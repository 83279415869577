import { cloneElement, Suspense } from 'react';
import './App.css';
import {
    HashRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import AppNavBar from './AppNavBar';
import Button from 'react-bootstrap/Button';
import { useAuth } from './context/auth-context';
import { useAsync } from './utils/use-async';
import Spinner from 'react-bootstrap/Spinner';
import { ErrorMessage } from './components/lib';

const routes = [
    {
        path: "*",
        component: Home
    }
];

export default function UnauthenticatedApp() {
    return (
        <Suspense fallback={'Loading...'}>
            <Router>
                <AppNavBar />

                <div className="container p-2">
                    <Switch>
                        {routes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))}
                    </Switch>
                </div>
            </Router>
        </Suspense>
    );
}

function LoginButton({ button }) {
    const { login } = useAuth();
    const { isLoading, isError, error, run } = useAsync()
    function doLogin(event) {
        run(
            login(),
        )
    }

    return <>
        {cloneElement(
            button,
            { type: 'submit', onClick: doLogin, disabled: isLoading },
            ...(Array.isArray(button.props.children)
                ? button.props.children
                : [button.props.children]),
            isLoading ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="ml-2"
            /> : null,
        )}
        <div>
            {isError ? <ErrorMessage error={error} /> : null}
        </div>
    </>;
}

function Home() {
    return (
        <>
            <h3>Welcome to PlexFetch</h3>
            <LoginButton button={<Button variant="warning">Login with Plex</Button>} />
        </>
    )
}
