import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { format, parseISO } from 'date-fns';
import { MediaType } from '../utils/data.ts';
import { client as apiClient } from '../utils/api-client';
import { useAsync } from '../utils/use-async';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import {Badge} from "react-bootstrap";

function MediaList({ media, request }) {
    return (
        <>
            <h4>{media.length} result(s)</h4>
            <div className="row row-cols-1 row-cols-md-1">
                {media.map((m, i) => (
                    <MediaItem key={i} media={m} request={request} />
                ))}
            </div>
        </>
    )
}

function MediaItem({ media, request }) {
    const { isLoading, run } = useAsync()
    let url = `https://www.themoviedb.org/${MediaType[media.type].toLowerCase()}/${media.mdbid}`;
    let date = request ? parseISO(media.request_date) : null;

    function requestMedia() {
        run(
            apiClient("request", { body: { type: media.type, mid: media.mdbid } })
                .then((r) => {
                    if (r.success) {
                        media.requested = true;
                        let message = <><strong>{media.title}</strong> {r.exists ? "already requested" : "has been requested"}</>
                        toast(message, { type: r.exists ? "info" : "success" })
                    } else {
                        let message = <>Error adding <strong>{media.title}</strong>, try later</>
                        toast(message, { type: "error" })
                    }
                })
        )
    }

    return (
        <>
            <div className="col mb-4">
                <Card bg={"dark"} text={"light"}>
                    <div className="row no-gutters">
                        <div className="col-md-2">
                            <a href={url} target="_blank" rel="noopener noreferrer">
                                <img src={media.poster} className="card-img-top" alt="Poster" />
                            </a>
                        </div>
                        <div className="col-md-10">
                            <div className="card-body overflow-auto" style={{ maxHeight: "270px" }}>
                                <h5 className="card-title">
                                    {media.title}{' '}
                                    {media.year ? <span className="text-muted">({media.year})</span> : null}{' '}
                                    <Badge bg={"warning"} text={"dark"}>{MediaType[media.type]}</Badge>
                                </h5>
                                {request ?
                                    <h6 className="card-subtitle mb-2 text-muted">
                                        Requested {format(date, 'PP')} by {media.requester}
                                    </h6>
                                    : null}
                                <p className="card-text">{media.overview}</p>
                            </div>
                        </div>
                    </div>
                    {request ? null :
                        <div className="card-footer text-end">
                            {media.requested
                                ? <Button variant={"outline-warning"} disabled>Requested</Button>
                                : <Button variant={"warning"} onClick={requestMedia} disabled={isLoading}>
                                    Request
                                    {isLoading ? <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-2"
                                    /> : null}
                                </Button>
                            }
                        </div>
                    }
                </Card>
            </div>
        </>
    )
}

export {
    MediaList,
    MediaItem,
}