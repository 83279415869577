const localStorageKey = '__plexfetch_token__'

async function client(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey)
  const headers = { 'content-type': 'application/json' }
  if (token) {
    headers["X-PlexFetch-Token"] = token;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  return window
    .fetch(`/api/${endpoint}`, config)
    .then(async r => {
      if (r.status === 401) {
        logout()
        // refresh the page for them
        window.location.reload()
        return
      }
      const data = await r.json()
      if (r.ok) {
        return data
      } else {
        return Promise.reject(data)
      }
    })
}

function logout() {
  window.localStorage.removeItem(localStorageKey)
}

export { client, localStorageKey, logout }