import { createContext, useLayoutEffect, useCallback, useMemo, useContext } from 'react';
import {bootstrapAppData} from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import {useAsync} from '../utils/use-async'

const AuthContext = createContext()
AuthContext.displayName = 'AuthContext'

const appDataPromise = bootstrapAppData()

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync()

  useLayoutEffect(() => {
    run(appDataPromise)
  }, [run])

  const login = useCallback(
    () => authClient.login().then(user => setData({user})),
    [setData],
  )
  const logout = useCallback(() => {
    authClient.logout()
    setData(null)
  }, [setData])

  const user = data?.user

  const value = useMemo(() => ({user, login, logout}), [
    login,
    logout,
    user,
  ])

  if (isLoading || isIdle) {
    return <h3>Loading...</h3>
  }

  if (isError) {
    return (
        <>
        <h3>Error</h3>
        <p>{error.message}</p>
        </>
    )
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }
  return context
}

export {AuthProvider, useAuth}