import { client, localStorageKey } from './api-client'
import { PlexOAuth } from './plex-api';

function handleUserResponse({ user: { token, ...user } }) {
    window.localStorage.setItem(localStorageKey, token)
    return user
}

function getUser() {
    const token = getToken()
    if (!token) {
        return Promise.resolve(null)
    }
    return client('me').then(data => data?.user)
}

async function login() {
    return await PlexOAuth().then((token) => {
        return client('login', { body: { token } }).then(handleUserResponse)
    })
    .catch((error) => {
        throw new Error("Plex login failed somehow, try again");
    });
}

function getToken() {
    return window.localStorage.getItem(localStorageKey)
}

function isLoggedIn() {
    return Boolean(getToken())
}

export { login, getToken, getUser, isLoggedIn }
export { logout } from './api-client'