import Alert from 'react-bootstrap/Alert';

const errorMessageVariants = {
    stacked: { display: 'block' },
    inline: { display: 'inline-block' },
}

function ErrorMessage({ error, variant = 'stacked', ...props }) {
    return (
        <Alert
            variant="danger"
            {...props}
        >
            <Alert.Heading>There was an error</Alert.Heading>
            <pre
                css={[
                    { whiteSpace: 'break-spaces', margin: '0', marginBottom: -5 },
                    errorMessageVariants[variant],
                ]}
            >
                {error.message}
            </pre>
        </Alert>
    )
}

export {
    ErrorMessage
}