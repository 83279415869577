import { Suspense, useState, useEffect } from 'react';
import './App.css';
import {
    HashRouter as Router,
    Switch,
    Route,
    useLocation,
    useParams,
    Redirect
} from 'react-router-dom';
import AppNavBar from './AppNavBar';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { client as apiClient } from './utils/api-client';
import { useAsync } from './utils/use-async';
import { ErrorMessage } from './components/lib';
import { MediaList } from './components/media-list';
import { MediaType } from './utils/data.ts';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './context/auth-context';

import 'react-toastify/dist/ReactToastify.css';
import Admin from './admin';

const routes = [
    {
        path: "/search",
        component: Search
    },
    {
        path: "/requests",
        component: Requests
    },
    {
        path: "/trending/:source/:duration",
        component: Trending
    },
    {
        path: "*",
        component: NoMatch
    }
];

export default function AuthenticatedApp() {
    const { user } = useAuth();

    return (
        <Suspense fallback={'Loading...'}>
            <Router>
                <AppNavBar />

                <div className="container p-2">
                    <ToastContainer theme={"dark"} />
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/search" />
                        </Route>
                        {user.admin ? <Route path="/admin" component={Admin} /> : null}
                        {routes.map((route, i) => (
                            <Route key={i} {...route} />
                        ))}
                    </Switch>
                </div>
            </Router>
        </Suspense>
    );
}

function Search() {
    const [result, setResult] = useState(null);
    const { isLoading, isError, error, run } = useAsync()

    function handleSearch(e) {
        e.preventDefault();
        const { query, type } = e.target.elements;
        if (query.value !== "") {
            setResult(null);
            run(
                apiClient("search", { body: { query: query.value, type: parseInt(type.value) } }).then((r) => setResult(r))
            );
        }
    };

    let entries = Object.keys(MediaType).filter(k => typeof MediaType[k] === "number");

    let options = entries.map((k) => {
        let v = MediaType[k];
        return <option key={v} value={v}>{k}</option>
    });

    return (
        <div>
            <Form onSubmit={handleSearch}>
                <InputGroup className="mb-3">
                    <FormControl id="query" placeholder="Search" variant={"dark"} bg={"dark"} style={{ width:"50%"}} />
                    <Form.Select defaultValue={MediaType.Movie} id="type">
                        {options}
                    </Form.Select>
                        <Button type="submit" disabled={isLoading} variant={"warning"}>Search</Button>
                </InputGroup>
            </Form>
            {isLoading ? <SpinnerWithText text={"Searching..."} /> : null}
            {isError ? <ErrorMessage error={error} /> : null}
            {result ? <MediaList media={result.results} /> : null}
        </div>
    );
}

function Requests() {
    const [requests, setRequests] = useState(null);
    const [url, setUrl] = useState("requests/my");
    const { isLoading, isError, error, run } = useAsync()

    useEffect(() => {
        setRequests(null);
        run(
            apiClient(url).then((data) => setRequests(data))
        );
    }, [run, url])

    return (
        <div>
            <h3 variant={"light"}>Requests</h3>
            <div>
                <ToggleButtonGroup type="radio" name="type" value={url} defaultValue="requests/my" onChange={(val) => { setUrl(val) }}>
                    <ToggleButton id={"all"} value="requests" variant={"warning"}>All requests</ToggleButton>
                    <ToggleButton id={"my"} value="requests/my" variant={"warning"}>Only my requests</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {isLoading ? <SpinnerWithText text={"Fetching..."} /> : null}
            {isError ? <ErrorMessage error={error} /> : null}
            {requests ? <MediaList media={requests.requests} request={true} /> : null}
        </div>
    );
}

function Trending() {
    let { source, duration } = useParams();
    const [trending, setTrending] = useState(null);
    const { isLoading, isError, error, run } = useAsync()

    useEffect(() => {
        setTrending(null);
        run(
            apiClient("trending", { body: { source, duration } }).then((data) => setTrending(data))
        );
    }, [run, source, duration])

    return (
        <div>
            <h3>Trending {source === "tv" ? "TV" : "movies"} {duration === "day" ? "today" : "this week"}</h3>
            {isLoading ? <SpinnerWithText text={"Fetching..."} /> : null}
            {isError ? <ErrorMessage error={error} /> : null}
            {trending ? <MediaList media={trending.trending} request={false} /> : null}
        </div>
    );
}

function NoMatch() {
    let location = useLocation();

    return (
        <h3>
            Unknown URL: <code>{location.pathname}</code>
        </h3>
    )
}

function SpinnerWithText({text}){
    return (
        <span style={{color: "#f8f9fa"}}>
            <Spinner as="span" animation="border" size="sm" role="loading" aria-hidden="true" variant={"light"} />{' '}
            {text}
        </span>
    )
}
