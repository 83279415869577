import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from './context/auth-context';
import {Container} from "react-bootstrap";

export default function AppNavBar() {
    const { user, logout } = useAuth();
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar bg="dark" variant="dark" expand="lg" sticky="top" expanded={expanded}>
            <Container>
            <Navbar.Brand as={Link} to="/">
                <img
                  alt=""
                  src="/logo192.png"
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
                PlexFetch
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse>
                {user &&
                    <>
                        <Nav className="me-auto">
                            <Nav.Link as={NavLink} to="/search" onClick={() => setExpanded(false)}>Search</Nav.Link>
                            <Nav.Link as={NavLink} to="/requests" onClick={() => setExpanded(false)}>Requests</Nav.Link>
                            <NavDropdown title="Trending" menuVariant={"dark"}>
                                <NavDropdown.Header>Movies</NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to="/trending/movie/day" onClick={() => setExpanded(false)}>Today</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/trending/movie/week" onClick={() => setExpanded(false)}>This Week</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Header>TV</NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to="/trending/tv/day" onClick={() => setExpanded(false)}>Today</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/trending/tv/week" onClick={() => setExpanded(false)}>This Week</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className={"d-flex"}>
                            <NavDropdown title={user?.username} align={"end"} menuVariant={"dark"}>
                                {user.admin ?
                                    <>
                                        <NavDropdown.Item as={NavLink} to="/admin" onClick={() => setExpanded(false)}>Admin</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </> : null}
                                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </>
                }
            </Navbar.Collapse>
                </Container>
        </Navbar>
    );
}